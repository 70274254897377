<template>
  <div class="rn-slider-area" id="home" v-if="NOTARIA && NOTARIA.Theme">
    <div class="slider-style-notin-bg-1 bg_image">
      <div class="slide slider-style-notin-1 slider-style-notin-filter-1 slider-paralax paralx-slider parallax d-flex align-center justify-center bg_image hardware-aceleration">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class=" banner-home inner text-left">
                <img v-if="NOTARIA.Theme.Home.Banner.Logo && !ModoAccesible" class="img"
                  src="~@/assets/images/logo-notariado.png" alt="Logo notariado" />
                <h1>
                  {{ NOTARIA.Theme.Home.Banner.Titulo.Texto }}
                </h1>
                <h2>
                  {{ NOTARIA.Theme.Home.Banner.Subtitulo.Texto }}
                </h2>
                <div class="slide-btn" v-if="NOTARIA.Theme.Home.Banner.ShowButton">
                  <v-btn class="rn-btn button-banner-notin" @click="scrollMeTo(NOTARIA.Theme.Home.Banner.Button.Click)">
                    {{ NOTARIA.Theme.Home.Banner.Button.Texto }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>

</template>
<script setup lang="ts">
import { useStore } from '../../store'
const store = useStore()
const { NOTARIA, ModoAccesible } = storeToRefs(store)
import { useUtils } from '#imports';
const { imagenIDtoSRC, $t } = useUtils()

import { useStyles } from '../../store/styles';
const style = useStyles();

const compSliderBackground = computed(() => {
  var bg = ""
  if (NOTARIA.Theme.Home.Banner.Imagen && NOTARIA.Theme.Home.Banner.Imagen != "") {
    bg = "background-image:url('" + imagenIDtoSRC(NOTARIA.Theme.Home.Banner.Imagen) + "');"
  } else {
    bg = "background-image:url('" + require('@/assets/images/bg/bg-image-10.jpg') + "');"
  }
  return bg
})

function scrollMeTo(refName) {
  var element = this.$refs[refName];
  var top = element.offsetTop;

  window.scrollTo(0, top);
}

const estiloTituloColor = style.ThemeHomeBanner?.Titulo?.css?.Color ? style.ThemeHomeBanner.Titulo.css.Color : ""
const estiloTituloShadow = style.ThemeHomeBanner?.Titulo?.css?.BackgroundColor ? "4px 2px 6px "+style.ThemeHomeBanner.Titulo.css.BackgroundColor:""
const estiloTituloFont = style.ThemeHomeBanner?.Titulo?.css?.Font ? style.ThemeHomeBanner.Titulo.css.Font : ""
const estiloTituloSize = style.ThemeHomeBanner?.Titulo?.css?.Size ? style.ThemeHomeBanner.Titulo.css.Size : ""
const estiloTituloWeight = style.ThemeHomeBanner?.Titulo?.css?.Weight ? style.ThemeHomeBanner.Titulo.css.Weight : ""
const estiloTituloAlign = style.ThemeHomeBanner?.Titulo?.css?.Align ? style.ThemeHomeBanner.Titulo.css.Align : ""

const estiloSubtituloColor = style.ThemeHomeBanner?.Subtitulo?.css?.Color ? style.ThemeHomeBanner.Subtitulo.css.Color : ""
const estiloSubtituloShadow = style.ThemeHomeBanner?.Subtitulo?.css?.BackgroundColor ? "4px 2px 6px "+style.ThemeHomeBanner.Subtitulo.css.BackgroundColor:""
const estiloSubtituloFont = style.ThemeHomeBanner?.Subtitulo?.css?.Font ? style.ThemeHomeBanner.Subtitulo.css.Font : ""
const estiloSubtituloSize = style.ThemeHomeBanner?.Subtitulo?.css?.Size ? style.ThemeHomeBanner.Subtitulo.css.Size : ""
const estiloSubtituloWeight = style.ThemeHomeBanner?.Subtitulo?.css?.Weight ? style.ThemeHomeBanner.Subtitulo.css.Weight : ""
const estiloSubtituloAlign = style.ThemeHomeBanner?.Subtitulo?.css?.Align ? style.ThemeHomeBanner.Subtitulo.css.Align : ""

const estiloButtonColor = style.ThemeHomeBanner?.Button?.css?.Color ? style.ThemeHomeBanner.Button.css.Color : ""
const estiloButtonFont = style.ThemeHomeBanner?.Button?.css?.Font ? style.ThemeHomeBanner.Button.css.Font : ""
const estiloButtonBackgoundColor = style.ThemeHomeBanner?.Button?.css?.BackgroundColor ? style.ThemeHomeBanner.Button.css.BackgroundColor : ""
const estiloButtonWeight = style.ThemeHomeBanner?.Button?.css?.Weight ? style.ThemeHomeBanner.Button.css.Weight : ""
const estiloButtonBorder = style.ThemeHomeBanner?.Button?.css?.Color ? "2px solid" + style.ThemeHomeBanner.Button.css.Color : ""

const imgFiltro = style.ThemeHomeBanner?.ImagenFiltro ? style.ThemeHomeBanner.ImagenFiltro : ""
const img = style.ThemeHomeBanner?.Imagen ? "url(" + imagenIDtoSRC(style.ThemeHomeBanner.Imagen)+ ")" : ""

</script>
<style>
.banner-home h1{
  color:v-bind(estiloTituloColor)!important;
    text-shadow: v-bind(estiloTituloShadow) !important;
    font-family: v-bind(estiloTituloFont);
    font-size: v-bind(estiloTituloSize) !important;
    font-weight: v-bind(estiloTituloWeight) !important;
    text-align: v-bind(estiloTituloAlign)!important;
}

.banner-home h2{
  color:v-bind(estiloSubtituloColor)!important;
    text-shadow: v-bind(estiloSubtituloShadow) !important;
    font-family: v-bind(estiloSubtituloFont);
    font-size: v-bind(estiloSubtituloSize) !important;
    font-weight: v-bind(estiloSubtituloWeight) !important;
    text-align: v-bind(estiloSubtituloAlign)!important;
}

.banner-home button{
    color:v-bind(estiloButtonColor)!important;
    font-family: v-bind(estiloButtonFont) !important;
    font-weight: v-bind(estiloButtonWeight) !important;
    background-color: v-bind(estiloButtonBackgoundColor) !important;
    border: v-bind(estiloButtonBorder) !important;
}
.slide{
  background-image:v-bind(img);
  -webkit-backdrop-filter: v-bind(imgFiltro);
  backdrop-filter:v-bind(imgFiltro) ;
}

</style>