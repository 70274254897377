<template>
<div class="rn-testimonial-area-notin rn-section-gap bg_color--3" id="testimonial"
  v-if="NOTARIA && NOTARIA.Theme && NOTARIA.Theme.Home.Testimonios.Mostrar">
    <v-container>
        <v-row>
            <v-col cols="12">
            <!-- Start Tab Content  -->
            <div class="rn-testimonial-content-notin testimonial-notin">
                <v-window v-model="tab" role="tablist" aria-busy="true">
                <v-window-item v-for="(item,i) in NOTARIA.Testimonios" :key="i" role="tab">
                    <v-card >
                      <v-card-text>
                          <div class="inner">
                          <p>
                              {{ item.Descripcion }}
                          </p>
                          </div>
                          <div class="author-info">
                                <span>{{ item.Nombre }}</span> -
                                <span v-for="i of item.Estrellas" :key="i">
                                  <v-icon>
                                    {{NOTARIA.Theme.Home.Testimonios.Icon}}
                                  </v-icon>
                                </span>
                          </div>
                      </v-card-text>
                    </v-card>
                </v-window-item>
                </v-window>
                <!-- End .v-tabs-items -->
                <v-tabs v-model="tab" centered center-active :show-arrows="false" class="testimonial-tabs" >                  
                    <v-tab v-for="(item,i) in NOTARIA.Testimonios" :key="i" role="tab">
                        <div class="thumb" v-if="item.Imagen">
                          <img :alt="'Avatar de ' + item.Nombre" :src="imagenIDtoSRC(item.Imagen)" width="60" height="60" />
                        </div>
                        <div class="thumb" v-else>
                          <img :alt="'Avatar de ' + item.Nombre" :src="imagenIDtoSRC(NOTARIA.Theme.Menu.Logo)"  width="60" height="60" />
                        </div>
                    </v-tab>
                </v-tabs>
                <!-- End .v-tabs -->
            </div>
            <!-- End .rn-testimonial-content -->
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script setup>
import {useStore} from '../store/index.ts'
const store = useStore()
const {NOTARIA} = storeToRefs(store)
import { useUtils } from '#imports';
const {imagenIDtoSRC,$t}=useUtils()
const tab = ref(null)
</script>
<style lang="scss" scoped>
.testimonial-tabs .v-slide-group__prev, .testimonial-tabs .v-slide-group__prev{
 display: none;
}
.v-tab, .v-slide-group__content,.v-slide-group__container,.v-slide-group{
  height: 100px !important;
}
</style>