<template>
<div class="rn-blog-area blog-area rn-section-gap bg_color--1" id="blog"
  v-if="NOTARIA && NOTARIA.Theme && NOTARIA.Theme.Home.Blog.Mostrar">
    <v-container>
        <div class="banner-home-blog-notin">
          <v-row>
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="text-left">
                  <h2 class="heading-title">{{NOTARIA.Theme.Home.Blog.Titulo.Texto}}</h2>
                  <p>
                    {{NOTARIA.Theme.Home.Blog.Descripcion.Texto}}
                  </p>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12">
              <div class="blog-btn text-left text-md-right mt_sm--10 mt_md--10">
                  <a class="btn-transparent rn-btn-dark ver-home-blog-notin" href="/blog"
                  ><span class="text">Ver todas las entradas</span></a
                  >
              </div>
            </v-col>
          </v-row>
        </div>
        <BlogNotinList :Home="true" type="home-blog"/>
    </v-container>
</div>
</template>

<script setup>
import {useStore} from '../store/index.ts'
const store = useStore()
const {NOTARIA} = storeToRefs(store)
import { useUtils } from '#imports';
const {imagenIDtoSRC,$t}=useUtils()


import { useStyles } from '../store/styles.ts';
const style = useStyles();

const control = style.ThemeHomeBlog

const estiloTituloColor = control.Titulo.css.Color
const estiloTituloBackgroundColor = control.Titulo.css.BackgroundColor
const estiloTituloShadow = control.Titulo.css.BackgroundColor ? "4px 2px 6px "+control.Titulo.css.BackgroundColor:""
const estiloTituloFont = control.Titulo.css.Font
const estiloTituloSize = control.Titulo.css.Size
const estiloTituloWeight = control.Titulo.css.Weight
const estiloTituloAlign = control.Titulo.css.Align

const estiloDescripcionColor = control.Descripcion.css.Color
const estiloDescripcionBackgroundColor = control.Descripcion.css.BackgroundColor
const estiloDescripcionShadow = control.Descripcion.css.BackgroundColor ? "4px 2px 6px "+ control.Descripcion.css.BackgroundColor:""
const estiloDescripcionFont = control.Descripcion.css.Font
const estiloDescripcionSize = control.Descripcion.css.Size
const estiloDescripcionWeight = control.Descripcion.css.Weight
const estiloDescripcionAlign = control.Descripcion.css.Align

const estiloTarjetaTituloColor = control.Tarjeta.Titulo.css.Color
const estiloTarjetaTituloBackgroundColor = control.Tarjeta.Titulo.css.BackgroundColor
const estiloTarjetaTituloShadow = control.Tarjeta.Titulo.css.BackgroundColor ? "4px 2px 6px "+ control.Tarjeta.Titulo.css.BackgroundColor:""
const estiloTarjetaTituloFont = control.Tarjeta.Titulo.css.Font
const estiloTarjetaTituloSize = control.Tarjeta.Titulo.css.Size
const estiloTarjetaTituloWeight = control.Tarjeta.Titulo.css.Weight
const estiloTarjetaTituloAlign = control.Tarjeta.Titulo.css.Align

const estiloTarjetaDescripcionColor = control.Tarjeta.Descripcion.css.Color
const estiloTarjetaDescripcionBackgroundColor = control.Tarjeta.Descripcion.css.BackgroundColor
const estiloTarjetaDescripcionShadow = control.Tarjeta.Descripcion.css.BackgroundColor ? "4px 2px 6px "+ control.Tarjeta.Descripcion.css.BackgroundColor:""
const estiloTarjetaDescripcionFont = control.Tarjeta.Descripcion.css.Font
const estiloTarjetaDescripcionSize = control.Tarjeta.Descripcion.css.Size
const estiloTarjetaDescripcionWeight = control.Tarjeta.Descripcion.css.Weight
const estiloTarjetaDescripcionAlign = control.Tarjeta.Descripcion.css.Align

var estiloTarjetaDescripcionBackground
if(control.Tarjeta.FondoCSS.BackgroundColorGradient){
  estiloTarjetaDescripcionBackground = "linear-gradient(to right,"+control.Tarjeta.FondoCSS.BackgroundColor+","+ control.Tarjeta.FondoCSS.BackgroundColorGradient+")"
}else{
  estiloTarjetaDescripcionBackground = control.Tarjeta.FondoCSS.BackgroundColorGradient
}


</script>
<style lang="scss">
.banner-home-blog-notin
{
  .section-title-notin p
  {
    color:v-bind(estiloDescripcionColor) !important;
    text-shadow:v-bind(estiloDescripcionShadow)  !important;
    font-family:v-bind(estiloDescripcionFont)  !important;
    font-size:v-bind(estiloDescripcionSize)  !important;
    font-weight:v-bind(estiloDescripcionWeight)  !important;
    text-align:v-bind(estiloDescripcionAlign)  !important;
  }
}

.banner-home-blog-notin h2{
            color:v-bind(estiloTituloColor)  !important;
            text-shadow:v-bind(estiloTituloShadow)  !important;
            font-family:v-bind(estiloTituloFont)  !important;
            font-size:v-bind(estiloTituloSize) !important;
            font-weight:v-bind(estiloTituloWeight) !important;
            text-align:v-bind(estiloTituloAlign)  !important;
            }

            .banner-home-blog-notin p{
            color:v-bind(estiloDescripcionColor) !important;
            text-shadow:v-bind(estiloDescripcionShadow)  !important;
            font-family:v-bind(estiloDescripcionFont)  !important;
            font-size:v-bind(estiloDescripcionSize)  !important;
            font-weight:v-bind(estiloDescripcionWeight)  !important;
            text-align:v-bind(estiloDescripcionAlign)  !important;
            }

            .card-home-blog-notin h3, .card-home-blog-notin h5{
            color:v-bind(estiloTarjetaTituloColor) !important;
            text-shadow:v-bind(estiloTarjetaTituloShadow) !important;
            font-family:v-bind(estiloTarjetaTituloFont) !important;
            font-size:v-bind(estiloTarjetaTituloSize) !important;
            font-weight:v-bind(estiloTarjetaTituloWeight) !important;
            text-align:v-bind(estiloTarjetaTituloAlign) !important;
            }

            .card-home-blog-notin p{
            color:v-bind(estiloTarjetaDescripcionColor) !important;
            text-shadow:v-bind(estiloTarjetaDescripcionShadow) !important;
            text-align: v-bind(estiloTarjetaDescripcionAlign) !important;
            font-size:v-bind(estiloTarjetaDescripcionSize) !important;
            font-family:v-bind(estiloTarjetaDescripcionFont) !important;
            font-weight:v-bind(estiloTarjetaDescripcionWeight)!important;
            }

          .card-home-blog-notin a:before{
            background:v-bind(estiloTarjetaDescripcionBackground);
          }

</style>