<template>
  <div>
    <!-- Start Slider Area  -->
    <SliderBannerNotinOne />
    <!-- End Slider Area -->

    <!-- Start About Area -->
    <AboutPalabrasNotinOne />

    <!-- End About Area -->

    <!-- Start Service Area -->
    <ServiceNotinOne />
    <!-- End Service Area -->

    <!-- Start Counterup Area -->
    <!-- <div class="rn-counterup-area pt--25 pb--110 bg_color--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="section-title text-center">
                <h3 class="fontWeight500">Our Fun Facts</h3>
              </div>
            </v-col>
          </v-row>

          <CounterOne />
        </v-container>
      </div> -->
    <!-- End Counterup Area -->

    <!-- Start Blog Area  -->
    <BlogNotinOne />

    <!-- End Blog Area  -->
    <!-- Start Testimonial Area  -->
    <TestimonialNotinOne />
    <!-- Start Testimonial Area  -->
    <GalleryNotinOne />

    <!-- Start Contact Area  -->
    <ContactFormNotinOne v-if="NOTARIA && NOTARIA.Theme && NOTARIA.Theme.Home.Form.Mostrar" :contactForm="NOTARIA.Theme.Home.Form"/>
    <!-- <FormTwo /> -->
    <!-- End Contact Area  -->
  </div>
</template>

<script setup lang="ts">
import { toRaw } from "vue";
import { useStore } from '../store/index.ts'
const store = useStore()
const { NOTARIA } = storeToRefs(store)
import {useMetaTags} from '../composables/metaTags.ts'
const PageName="Home"
if(store.NOTARIA) useMetaTags(toRaw(store.NOTARIA),PageName)



</script>
