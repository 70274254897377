<template>
    <div
      class="rn-service-area service-area rn-section-gap bg_color--3" id="service"
       v-if="NOTARIA && NOTARIA.Theme && NOTARIA.Theme.Home.Servicios.Mostrar"
    >
        <v-container>
            <v-row>
                <v-col lg="6">
                  <div class="section-title-notin text-left mb--30 banner-home-servicios-notin">
                    <NuxtLink to="/servicios">
                      <h2 class="heading-title">
                        {{NOTARIA.Theme.Home.Servicios.Titulo.Texto}}
                      </h2>
                      
                    </NuxtLink>
                    <p >
                        {{NOTARIA.Theme.Home.Servicios.Descripcion.Texto}}
                      </p>
                  </div>
                </v-col>
            </v-row>
            <ServiceNotinList :object="NOTARIA.Theme.Home.Servicios.Tarjeta" :Home="true" type="home-servicios"/>
        </v-container>
    </div>
</template>

<script setup>
import {useStore} from '../store/index'
const store = useStore()
const {NOTARIA} = storeToRefs(store)
import { useUtils } from '#imports';
const {imagenIDtoSRC,$t}=useUtils()
import { useStyles } from '../store/styles';
const style = useStyles();


const estiloTituloColor = style.ThemeHomeService.Titulo.css.Color
const estiloTituloBackgroundColor = style.ThemeHomeService.Titulo.css.BackgroundColor
const estiloTituloShadow = style.ThemeHomeService.Titulo.css.BackgroundColor ? "4px 2px 6px "+style.ThemeHomeService.Titulo.css.BackgroundColor:""
const estiloTituloFont = style.ThemeHomeService.Titulo.css.Font
const estiloTituloSize = style.ThemeHomeService.Titulo.css.Size
const estiloTituloWeight = style.ThemeHomeService.Titulo.css.Weight
const estiloTituloAlign = style.ThemeHomeService.Titulo.css.Align

const estiloDescripcionColor = style.ThemeHomeService.Descripcion.css.Color
const estiloDescripcionBackgroundColor = style.ThemeHomeService.Descripcion.css.BackgroundColor
const estiloDescripcionShadow = style.ThemeHomeService.Descripcion.css.BackgroundColor ? "4px 2px 6px "+ style.ThemeHomeService.Descripcion.css.BackgroundColor:""
const estiloDescripcionFont = style.ThemeHomeService.Descripcion.css.Font
const estiloDescripcionSize = style.ThemeHomeService.Descripcion.css.Size
const estiloDescripcionWeight = style.ThemeHomeService.Descripcion.css.Weight
const estiloDescripcionAlign = style.ThemeHomeService.Descripcion.css.Align

const estiloTarjetaTituloColor = style.ThemeHomeService.Tarjeta.Titulo.css.Color
const estiloTarjetaTituloBackgroundColor = style.ThemeHomeService.Tarjeta.Titulo.css.BackgroundColor
const estiloTarjetaTituloShadow = style.ThemeHomeService.Tarjeta.Titulo.css.BackgroundColor ? "4px 2px 6px "+ style.ThemeHomeService.Tarjeta.Titulo.css.BackgroundColor:""
const estiloTarjetaTituloFont = style.ThemeHomeService.Tarjeta.Titulo.css.Font
const estiloTarjetaTituloSize = style.ThemeHomeService.Tarjeta.Titulo.css.Size
const estiloTarjetaTituloWeight = style.ThemeHomeService.Tarjeta.Titulo.css.Weight
const estiloTarjetaTituloAlign = style.ThemeHomeService.Tarjeta.Titulo.css.Align

const estiloTarjetaDescripcionColor = style.ThemeHomeService.Tarjeta.Descripcion.css.Color
const estiloTarjetaDescripcionBackgroundColor = style.ThemeHomeService.Tarjeta.Descripcion.css.BackgroundColor
const estiloTarjetaDescripcionShadow = style.ThemeHomeService.Tarjeta.Descripcion.css.BackgroundColor ? "4px 2px 6px "+ style.ThemeHomeService.Tarjeta.Descripcion.css.BackgroundColor:""
const estiloTarjetaDescripcionFont = style.ThemeHomeService.Tarjeta.Descripcion.css.Font
const estiloTarjetaDescripcionSize = style.ThemeHomeService.Tarjeta.Descripcion.css.Size
const estiloTarjetaDescripcionWeight = style.ThemeHomeService.Tarjeta.Descripcion.css.Weight
const estiloTarjetaDescripcionAlign = style.ThemeHomeService.Tarjeta.Descripcion.css.Align

var estiloTarjetaDescripcionBackground
if(style.ThemeHomeService.Tarjeta.FondoCSS.BackgroundColorGradient){
  estiloTarjetaDescripcionBackground = "linear-gradient(to right,"+style.ThemeHomeService.Tarjeta.FondoCSS.BackgroundColor+","+ style.ThemeHomeService.Tarjeta.FondoCSS.BackgroundColorGradient+")"
}else{
  estiloTarjetaDescripcionBackground = style.ThemeHomeService.Tarjeta.FondoCSS.BackgroundColorGradient
}

</script>
<style lang="scss">
.banner-home-servicios-notin
{
  .section-title-notin p
  {
    color:v-bind(estiloDescripcionColor) !important;
    text-shadow:v-bind(estiloDescripcionShadow)  !important;
    font-family:v-bind(estiloDescripcionFont)  !important;
    font-size:v-bind(estiloDescripcionSize)  !important;
    font-weight:v-bind(estiloDescripcionWeight)  !important;
    text-align:v-bind(estiloDescripcionAlign)  !important;
  }
}

.banner-home-servicios-notin h2{
            color:v-bind(estiloTituloColor)  !important;
            text-shadow:v-bind(estiloTituloShadow)  !important;
            font-family:v-bind(estiloTituloFont)  !important;
            font-size:v-bind(estiloTituloSize) !important;
            font-weight:v-bind(estiloTituloWeight) !important;
            text-align:v-bind(estiloTituloAlign)  !important;
            }

            .banner-home-servicios-notin p{
            color:v-bind(estiloDescripcionColor) !important;
            text-shadow:v-bind(estiloDescripcionShadow)  !important;
            font-family:v-bind(estiloDescripcionFont)  !important;
            font-size:v-bind(estiloDescripcionSize)  !important;
            font-weight:v-bind(estiloDescripcionWeight)  !important;
            text-align:v-bind(estiloDescripcionAlign)  !important;
            }

            .card-home-servicios-notin h3, .card-home-servicios-notin h5{
            color:v-bind(estiloTarjetaTituloColor) !important;
            text-shadow:v-bind(estiloTarjetaTituloShadow) !important;
            font-family:v-bind(estiloTarjetaTituloFont) !important;
            font-size:v-bind(estiloTarjetaTituloSize) !important;
            font-weight:v-bind(estiloTarjetaTituloWeight) !important;
            text-align:v-bind(estiloTarjetaTituloAlign) !important;
            }

            .card-home-servicios-notin p{
            color:v-bind(estiloTarjetaDescripcionColor) !important;
            text-shadow:v-bind(estiloTarjetaDescripcionShadow) !important;
            text-align: v-bind(estiloTarjetaDescripcionAlign) !important;
            font-size:v-bind(estiloTarjetaDescripcionSize) !important;
            font-family:v-bind(estiloTarjetaDescripcionFont) !important;
            font-weight:v-bind(estiloTarjetaDescripcionWeight)!important;
            }

          .card-home-servicios-notin a:before{
            background:v-bind(estiloTarjetaDescripcionBackground);
          }

</style>