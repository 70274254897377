<template>
  <div class="rn-gallery-area gallery-area rn-section-gap bg_color--1" id="homegallery"
    v-if="NOTARIA && NOTARIA.Theme && NOTARIA.Theme.Home.Gallery.Mostrar">
    <v-container>
      <div>
        <v-row>
          <v-col sm="12" cols="12">
            <div class="section-title-notin text-left banner-home-gallery-notin content">
              <h2 class="heading-title">{{ NOTARIA.Theme.Home.Gallery.Titulo.Texto }}</h2>
              <p>
                {{ NOTARIA.Theme.Home.Gallery.Descripcion.Texto }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row class="mt--30">
        <!-- Start Single Gallery Area  -->
        <v-col :cols=comNumColumnas v-for="archivo in compArchivos" :key="archivo.id" class="d-flex child-flex py-4">
          <!-- <div class="thumbnail" v-if="archivo.id && archivo.showInGallerySection"> -->
            <v-img v-if="archivo.id && archivo.showInGallerySection" :src="imagenIDtoSRC(archivo.id)" aspect-ratio="1" class="bg-grey-lighten-2" cover />
          <!-- </div> -->
        </v-col>
        <!-- End Single Gallery Area  -->
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { useStore } from '../store/index.ts'
const store = useStore()
const { NOTARIA } = storeToRefs(store)
import { useUtils } from '#imports';
const { imagenIDtoSRC, $t } = useUtils()

import { useStyles } from '../store/styles.ts';
const style = useStyles();

const control = style.ThemeHomeGallery


const compArchivos = computed(() => {
  return store.NOTARIA.Archivos.filter(a => a.showInGallerySection)
})
const comNumColumnas = computed(() => {
  if (store.NOTARIA.Theme.Home.Gallery.NumeroColumnas == 1) {
    return 12
  } else if (store.NOTARIA.Theme.Home.Gallery.NumeroColumnas == 2) {
    return 6
  } else if (store.NOTARIA.Theme.Home.Gallery.NumeroColumnas == 3) {
    return 4
  } else if (store.NOTARIA.Theme.Home.Gallery.NumeroColumnas == 4) {
    return 3
  }
})
const estiloTituloColor = control.Titulo.css.Color
const estiloTituloBackgroundColor = control.Titulo.css.BackgroundColor
const estiloTituloShadow = control.Titulo.css.BackgroundColor ? "4px 2px 6px "+control.Titulo.css.BackgroundColor:""
const estiloTituloFont = control.Titulo.css.Font
const estiloTituloSize = control.Titulo.css.Size
const estiloTituloWeight = control.Titulo.css.Weight
const estiloTituloAlign = control.Titulo.css.Align

const estiloDescripcionColor = control.Descripcion.css.Color
const estiloDescripcionBackgroundColor = control.Descripcion.css.BackgroundColor
const estiloDescripcionShadow = control.Descripcion.css.BackgroundColor ? "4px 2px 6px "+ control.Descripcion.css.BackgroundColor:""
const estiloDescripcionFont = control.Descripcion.css.Font
const estiloDescripcionSize = control.Descripcion.css.Size
const estiloDescripcionWeight = control.Descripcion.css.Weight
const estiloDescripcionAlign = control.Descripcion.css.Align
</script>
<style type="scss" scoped>

.banner-home-gallery-notin
{
  .section-title-notin p
  {
    color:v-bind(estiloDescripcionColor) !important;
    text-shadow:v-bind(estiloDescripcionShadow)  !important;
    font-family:v-bind(estiloDescripcionFont)  !important;
    font-size:v-bind(estiloDescripcionSize)  !important;
    font-weight:v-bind(estiloDescripcionWeight)  !important;
    text-align:v-bind(estiloDescripcionAlign)  !important;
  }
}

.banner-home-gallery-notin h2{
            color:v-bind(estiloTituloColor)  !important;
            text-shadow:v-bind(estiloTituloShadow)  !important;
            font-family:v-bind(estiloTituloFont)  !important;
            font-size:v-bind(estiloTituloSize) !important;
            font-weight:v-bind(estiloTituloWeight) !important;
            text-align:v-bind(estiloTituloAlign)  !important;
            }

            .banner-home-gallery-notin p{
            color:v-bind(estiloDescripcionColor) !important;
            text-shadow:v-bind(estiloDescripcionShadow)  !important;
            font-family:v-bind(estiloDescripcionFont)  !important;
            font-size:v-bind(estiloDescripcionSize)  !important;
            font-weight:v-bind(estiloDescripcionWeight)  !important;
            text-align:v-bind(estiloDescripcionAlign)  !important;
            }
.v-img{
  width: 100%;
}
</style>