<template>
  <div class="about-area rn-section-gap bg_color--1" id="about" v-if="NOTARIA && NOTARIA.Theme && NOTARIA.Theme.Home.PN.Mostrar">
    <div class="about-wrapper">
      <div class="container">
        <v-container>
          <v-row align="center" class="row--35">
            <v-col lg="6" md="6" cols="12">
              <div class="thumbnail">
                <img
                  class="w-100"
                  :src="imagenIDtoSRC(NOTARIA.Theme.Home.PN.Imagen)"
                  alt="Fotografía del Notario"
                />
               
              </div>
            </v-col>
            <v-col lg="6" md="6" cols="12">
              <div class="pn content section-title-notin about-inner inner">
                  <h2 class="heading-title" >
                    {{NOTARIA.Theme.Home.PN.Titulo.Texto}}
                  </h2>
                  <p v-html="NOTARIA.Theme.Home.PN.Descripcion.Texto.replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>
              </div>
              
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useStore} from '../store/index.ts'
const store = useStore()
const {NOTARIA} = storeToRefs(store)
import { useUtils } from '#imports';
const {imagenIDtoSRC,$t}=useUtils()

import { useStyles } from '../store/styles';
const style = useStyles();

const estiloTituloColor = style.ThemeHomePalabrasNotario?.Titulo?.css?.Color ? style.ThemeHomePalabrasNotario.Titulo.css.Color : ""
const estiloTituloShadow = style.ThemeHomePalabrasNotario?.Titulo?.css?.BackgroundColor ? "4px 2px 6px "+style.ThemeHomePalabrasNotario.Titulo.css.BackgroundColor:""
const estiloTituloFont = style.ThemeHomePalabrasNotario?.Titulo?.css?.Font ? style.ThemeHomePalabrasNotario.Titulo.css.Font : ""
const estiloTituloSize = style.ThemeHomePalabrasNotario?.Titulo?.css?.Size ? style.ThemeHomePalabrasNotario.Titulo.css.Size : ""
const estiloTituloWeight = style.ThemeHomePalabrasNotario?.Titulo?.css?.Weight ? style.ThemeHomePalabrasNotario.Titulo.css.Weight : ""
const estiloTituloAlign = style.ThemeHomePalabrasNotario?.Titulo?.css?.Align ? style.ThemeHomePalabrasNotario.Titulo.css.Align : ""

const estiloDescripcionColor = style.ThemeHomePalabrasNotario?.Descripcion?.css?.Color ? style.ThemeHomePalabrasNotario.Descripcion.css.Color : ""
const estiloDescripcionShadow = style.ThemeHomePalabrasNotario?.Descripcion?.css?.BackgroundColor ? "4px 2px 6px "+style.ThemeHomePalabrasNotario.Descripcion.css.BackgroundColor:""
const estiloDescripcionFont = style.ThemeHomePalabrasNotario?.Descripcion?.css?.Font ? style.ThemeHomePalabrasNotario.Descripcion.css.Font : ""
const estiloDescripcionSize = style.ThemeHomePalabrasNotario?.Descripcion?.css?.Size ? style.ThemeHomePalabrasNotario.Descripcion.css.Size : ""
const estiloDescripcionWeight = style.ThemeHomePalabrasNotario?.Descripcion?.css?.Weight ? style.ThemeHomePalabrasNotario.Descripcion.css.Weight : ""
const estiloDescripcionAlign = style.ThemeHomePalabrasNotario?.Descripcion?.css?.Align ? style.ThemeHomePalabrasNotario.Descripcion.css.Align : ""

</script>
<style>
  .pn p{
    color:v-bind(estiloDescripcionColor)!important;
    text-shadow: v-bind(estiloDescripcionShadow) !important;
    font-family: v-bind(estiloDescripcionFont);
    font-size: v-bind(estiloDescripcionSize) !important;
    font-weight: v-bind(estiloDescripcionWeight) !important;
    text-align: v-bind(estiloDescripcionAlign)!important;
  }

  .pn h2 {
    color:v-bind(estiloTituloColor)!important;
    text-shadow: v-bind(estiloTituloShadow) !important;
    font-family: v-bind(estiloTituloFont);
    font-size: v-bind(estiloTituloSize) !important;
    font-weight: v-bind(estiloTituloWeight) !important;
    text-align: v-bind(estiloTituloAlign)!important;
  }
 
</style>